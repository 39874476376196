@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700&display=swap');


body,
button {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  overflow-x: hidden;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: unset;
}

strong {
  font-weight: 500;
}

.appContainer {
  min-height: 90vh;
}