.day-content {
  margin-top: 12px;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}

.horarios-container {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 30%;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.hora {
  margin-top: 5px;
  padding: 5px;
  color: #000;
  border-bottom: 1px solid;
  margin-right: 5px;
  margin-bottom: 10px;
}

.cardDay {
  border-radius: 1.25rem;
  background: #FFF;
  box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
  margin-top: 5px;
  transition: all ease-in .3s;
}

.cardDay:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.canceled {
  background-color: #B4514B;
  color: white;
}

.noAppointments {
  font-size: 2rem;
  margin: 3rem;
  color: black;
  display: flex;
  justify-content: center;
}

.circularProg {
  display: flex;
  justify-content: center;
}

.downloadButtonExcel {
  cursor: pointer;
  padding: 10px 20px;
  border: 4px solid #D9D9D9;
  border-radius: 10px;
  color: #4A4A4A;
  font-size: 18px;
  gap: 18px;
  transition: background-color 0.8s;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.downloadButtonExcel:hover {
  background-color: #B2B1B1;
  color: #fff;
}

.containerButtonExcel {
  display: flex;
  justify-content: center;
}