.newsContainer {
    margin: 0.8rem 3.75rem 1.2rem 3.75rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    column-gap: 2rem;
    justify-items: center;
    grid-row-gap: 3.75rem;
}

.buttonB {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #000;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
    margin-left: 6%;
    margin-top: 13px;
}

.selectedContainer .newsContainer {
    width: 100%;
    margin: 3rem 0 0 0;
}

.selectedContainer {
    margin: 0.8rem 3.75rem 1.2rem 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.articleImg {
    width: 23.25rem;
    height: 15.5rem;
    cursor: pointer;
    position: relative;
}

.articleImg div {
    color: #FFF;
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    bottom: .5rem;
    padding: 0 1rem;
}

.selectedTitle {
    color: #000;
    font-size: 2.25rem;
    font-weight: 600;
    display: flex;
    width: min(90%, 65rem);
}

.selectedImg,
.selectedContent {
    width: min(90%, 65rem);
}

.selectedContent {
    margin-top: 2rem;
    color: #000;
    font-family: Roboto;
}

.selectedContent h2 {
    font-size: 2rem;
    font-weight: 500;
}

.selectedContent p {
    font-size: 1.5rem;
    font-weight: 300;
}

.line {
    height: 0.1rem;
    width: 87%;
    background-color: black;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:1300px) {
    .newsContainer {
        margin: 1rem 0.5rem;
        display: flex;
        flex-direction: column;
    }

    .articleImg {
        width: 100%;
    }

    .selectedContainer {
        margin: 0;
    }

    .selectedContainer .newsContainer {
        margin: 5rem 3.75rem;
        width: 90%;
    }
    
    .buttonB {
        margin-left: 1rem;
    }
}
