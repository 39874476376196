.month {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
}

.monthContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    margin-top: 12px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.content-day {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.monthContainer .day {
    padding: 10px;
    text-align: center;
    border: 2px solid white;
    cursor: pointer;
    transition: 0.8s;
    border-radius: 14px;
    color: #fff;
}

.number {
    font-size: 18px;
    font-weight: bold;
}

.turn {
    font-size: 13px;
    margin-top: 2px;
    min-height: 1rem;
}

.monthContainer .day:hover {
    border: 2px solid black;
}

.monthContainer .day.today {
    border: 2px solid black;
}

.monthContainer .day.has-number {
    background-color: #34A8E9;
}

.monthContainer .day.no-number {
    background-color: #D9D9D9;
    color: black;
}

.monthWeek {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
}

.monthWeek .dayMonth {
    text-align: center;
    border-bottom: 1px solid;
    width: 100%;
}

.banned {
    background-color: #D9D9D9 !important;
}

.banned span {
    color: black;
}