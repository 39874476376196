.step0Container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.step0Container .container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.step0Container .title {
    font-size: 2rem;
    font-weight: 300;
    width: 90%;
    text-align: left;
}

.step0Container .question {
    text-align: center;
    font-size: 2rem;
    font-weight: 300;
    margin: 6rem 0 4rem 0;
}

.step0Container.step1 .question {
    margin: 2rem 0 4rem 0;
}

.step0Container .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 8rem;
}

.step0Container .buttonContainer button {
    width: 22.125rem;
    height: 3.625rem;
    flex-shrink: 0;
    border-radius: 1.5rem;
    background: #C2E36D;
    font-size: 1.75rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}

.step0Container .buttonContainer button svg {
    width: 2.25rem;
    height: 2.25rem;
}

.appointContainer,
.appointBoxContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.appointImg,
.appointImgShadow,
.appointImgText {
    width: 100%;
    height: 12rem;
}

.appointImg {
    object-fit: cover;
}

.appointImgShadow {
    background: linear-gradient(90deg, rgb(0 0 0) 0%, rgba(0, 0, 0, 0.00) 99.41%), #d3d3d370 0px -586.11px / 100% 425.31% no-repeat;
    position: absolute;
    opacity: .5;
    display: flex;
    align-items: center;
}

.appointImgText {
    position: absolute;
    color: #FFF;
    font-family: Roboto;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    align-items: center;
    padding: 0 0 0 7.5rem;
    gap: 0.5rem;
    width: calc(100% - 7.5rem);
}

.appointBoxContainer {
    margin: 3rem 7.5rem;
    min-height: 275px;
}

.appointTitle {
    margin-bottom: 3rem;
    color: #000;
    font-family: "Roboto";
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.appointTitle span {
    font-weight: 500;
}

.pickersContainer {
    display: flex;
    gap: 3.5rem;
    justify-content: center;
}

.calendarContainer,
.clockContainer {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 30rem;
    height: 26rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiDateCalendar-root {
    margin: 0 !important;
    transform: scale(130%);
}

.clockDivisor {
    display: flex;
}

.clockContainer {
    flex-direction: column;
    gap: 1.25rem;
}

.clockTitle {
    margin-top: 1rem;
    margin-bottom: .5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.clockDivisor {
    height: 100%;
    gap: 1.5rem;
    margin-bottom: 3.5rem;
    margin-top: .25rem;
}

.clockLeft,
.clockRight {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.25rem;
    justify-items: center;
}

.clockHour {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;
}

.clockHourSelected {
    border-radius: 0.5rem;
    background: #1976d2;
    color: white;
}

.clockDisabled {
    color: lightgrey;
    cursor: default;
    pointer-events: none;
}

.appointBoxButtons {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem 1rem 1rem;
}

.backButton,
.fowardButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 2.6875rem;
    max-width: 300px;
    right: 1rem;
    gap: 0.5rem;
    color: #000;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0 0.5rem;
    border: none;
    cursor: pointer;
    padding: 0 1rem;
    z-index: 3;
}

.fowardButton {
    background: #456296;
    color: white;
    margin-left: auto;
}

.fowardButton.goHomeVariant {
    flex-direction: row-reverse;
    background: #456296;
    position: initial;
    margin-top: 5rem;
    margin-left: 0;
}

button.disabled {
    background: #ECECEC;
    color: rgba(0, 0, 0, 0.70);
    font-weight: 500;
    cursor: unset;
}

button.disabled:hover {
    background-color: #ECECEC;
}

button.disabled svg path {
    fill: rgba(0, 0, 0, 0.70);
}

.resumeContainer {
    display: flex;
}

.resumeText {
    color: rgba(0, 0, 0, 0.60);
    font-family: "Roboto";
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.75rem;
}

.canceledImg {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
}

.canceledSvg {
    width: 10rem;
    height: 10rem;
}

.canceledImg .resumeInfo {
    width: 80%;
    padding: 1.5rem 2.5rem;
}

.resumeInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0 2.5rem;
    position: relative;
    color: #000;
    font-family: "Roboto";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.resumeInfo svg {
    position: absolute;
    top: -1.5rem;
    left: calc(100% - 1.5rem);
}

.resumeText,
.resumeInfo {
    width: 50%;
}

.formContainer {
    display: flex;
    justify-content: center;
}

.formContainer form {
    display: flex;
    gap: 5rem;
}

.sectionForm {
    display: flex;
    flex-direction: column;
    min-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.formTitle {
    font-family: "Roboto";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 2rem 0;
}

.formContainer .inputGroup {
    min-height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 1.5rem;
}

.formContainer .inputGroup input {
    width: 100%;
    padding: .25rem 1.25rem .25rem 2rem;
    font-family: "Roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.formContainer .inputGroup .icon {
    position: absolute;
    left: .25rem;
    width: 24px;
    height: 24px;
}

.dniContainer {
    margin: 0 5rem 5rem 5rem;
}

.mpContainer {
    display: none;
}

.resumeInfo.errorPage {
    width: auto;
    padding: 1.75rem 2.5rem;
}

@media (max-width:1300px) {
    .appointTitle {
        font-size: 1.5rem;
        text-align: center;
    }

    .pickersContainer,
    .resumeContainer {
        flex-direction: column;
        align-items: center;
        transform: scale(0.8);
        position: relative;
        top: -5rem;
    }

    .resumeText {
        width: 100%;
    }

    .resumeInfo {
        width: 90%;
    }

    .resumeContainer {
        margin-top: 5rem;
    }

    .resumeContainer {
        align-items: center;
        gap: 2rem;
    }

    .resumeInfo {
        padding: 2.5rem;
    }

    .appointBoxContainer {
        margin: 3rem auto;
    }

    .formContainer form {
        flex-direction: column;
    }

    .appointImgText {
        padding: 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    .step0Container .buttonContainer {
        flex-direction: column;
        align-items: center;
        gap: 4rem;
    }

    .step0Container .title {
        text-align: center;
    }

    .appointContainer {
        margin-bottom: 0;
    }

    .appointBoxContainer {
        margin: 1.5rem 0 5rem 0;
    }

    .step0Container .question {
        margin: 2rem 0;
    }

    .step1 .question {
        margin: 1rem 0 2rem !important;
    }

    .step0Container .question,
    .step0Container .title {
        font-size: 1.25rem;
    }

    .buttonContainer button {
        transform: scale(0.85);
    }

    .step0Container .buttonContainer {
        gap: 1rem;
    }

    .dniContainer {
        margin: 0 1rem;
    }

    .pickersBox {
        height: 50rem;
    }

    .success-page .appointBoxContainer {
        margin-bottom: 0;
    }

    .success-page .appointBoxButtons {
        flex-direction: column-reverse;
        align-items: center;
        gap: 1rem;
    }

    .appointBoxContainer.errorPage {
        margin-bottom: 0;
        height: 0;
    }

    .resumeContainer.errorPage {
        flex-direction: column-reverse;
    }

    .canceledImg {
        width: 90%;
    }

    .canceledPage .resumeContainer{
        flex-direction: column-reverse;
    }
}