.week {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
}

.week-content {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin: 10px;
}

.dayWeek {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    border: 3px solid white;
    cursor: pointer;
    transition: 0.8s;
    border-radius: 20px;
    color: #4A4A4A;
    background-color: #D9D9D9;
    font-size: 20px;
}

.dayWeek:hover:not(:has(.card:hover)) {
    border: 2px solid black;
}

.dayWeek.today {
    border: 1px solid black;
}

.Semana {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    margin-top: 15px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
}

.Semana .day {
    text-align: center;
}

.time-column {
    padding: 10px;
}

.time {
    margin-top: 5px;
    color: #000;
    font-size: 15px;
}

.card {
    border-radius: 0.5rem;
    height: 20px;
    width: 85px;
    background: #FFF;
    box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 50px;
    gap: 2px;
    position: relative;
    font-size: 10px;
    margin-top: 5px;
    transition: all ease-in .3s;
    border: 2px solid transparent;
}

.card:hover {
    transform: scale(1.05);
    cursor: pointer;
    border: 2px solid black;
}

.canceled {
    background-color: #B4514B;
    color: white;
}

.weekfcol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    text-transform: capitalize;
}

@media (max-width:1024px) {
    .card {
        padding: 1rem;
        font-size: 1rem;
        width: max-content;
    }

    .dayWeek {
        min-width: 140px;
    }

    .week {
        overflow-x: scroll;
    }

    .horarios-container{
        width: 100%;
    }
}