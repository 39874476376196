.floatButton {
    width: 5rem;
    height: 5rem;
    position: fixed;
    bottom: 7rem;
    right: 1rem;
    background-color: #456396;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: all 0.3s ease;
}

.wp {
    bottom: 1rem;
    background-color: #25d366
}

.floatButton:hover {
    transform: scale(1.1);
}

@media (max-width:768px) {
    .floatButton {
        width: 4rem;
        height: 4rem;
        bottom: 6rem;
    }

    .wp {
        bottom: 1rem;
    }

    .floatButton svg {
        width: 60%;
        height: 100%;
    }
}