.headerContainer {
    display: flex;
    justify-content: space-between;
    height: 6rem;
    padding: 1rem;
    align-items: center;
}

.logoHeader img {
    max-width: 15rem;
    max-height: 10rem;
    object-fit: contain;
}

.botonHeaderCont {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.botonHeader {
    border: none;
    min-height: calc(2.75rem - 2px);
    cursor: pointer;
    color: #456296;
    font-weight: 600 !important;
    font-size: 1.25rem;
    border: 3px solid transparent;
    border-width: 0 0 3px 0;
    transition: border-color 0.5s ease;
    padding-inline: 2rem;
}

.botonHeader:hover {
    border: 3px solid #456296;
    border-width: 0 0 3px 0;
    transition: border-color 0.5s ease;
}

.botonHeader.active {
    border-bottom: 3px solid #456296;
}

.containerHeaderButton {
    position: relative;
}

.listHeader {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: -60%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
    padding: 0.8rem 1.8rem 0.2rem 1.8rem;
    border-radius: 0.5rem;
    z-index: 2;
    width: 175%;
}

.buttonGreen,
.buttonRed,
.buttonBlue {
    min-width: 10.25rem;
    border: 3px solid transparent;
    border-width: 0 0 3px 0;
    transition: border-color 0.5s ease;
}

.buttonGreen {
    color: #4BB455;
}

.buttonRed {
    color: #B4514B;
}

.buttonBlue {
    color: #456296;
}

.buttonGreen:hover {
    border: 3px solid #4BB455;
    border-width: 0 0 3px 0;
    transition: border-color 0.5s ease;
}

.buttonRed:hover {
    border: 3px solid #B4514B;
    border-width: 0 0 3px 0;
    transition: border-color 0.5s ease;
}

.MuiCollapse-vertical .MuiListItemText-primary {
    padding-left: 1rem;
}

.drawerButtonClose {
    width: 100%;
    display: flex;
    justify-content: end;
}

.drawerButtonClose button {
    padding: 1rem 1rem 0 0;
}