.infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: relative;
}

.floatInfo {
    width: 90vw;
    position: absolute;
    bottom: 95%;
    z-index: 3;
}

.floatTitle {
    color: #456396;
    font-family: 'Roboto', sans-serif;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 49rem;
    margin: 0 0 3rem 0;
}

.floatText {
    display: flex;
    background-color: white;
    padding: 3rem;
    color: rgba(0, 0, 0, 0.70);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.1875rem;
    flex-direction: column;
    padding-bottom: 0;
}

.floatText p {
    margin: 0;
}

.floatText img {
    width: 12rem;
    height: 12rem;
    object-fit: contain;
}

.contactInfo {
    width: 100%;
    background-color: white;
    margin: 3rem 0;
}

.contactInfoTitle {
    color: #456396;
    font-family: Roboto;
    font-size: 3.375rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 0;
}

.contactInfoTitle span {
    font-weight: 500;
}

.contactDataContainer,
.infoTextImg {
    display: flex;
}

.infoTextImg {
    gap: 2rem;
}

.contactData {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.1875rem;
}

.contactData,
.contactMap {
    width: 50%;
}

.contactInfoTitle,
.contactDataContainer {
    margin-left: 7vw;
    width: calc(100% - 7vw);
}

.contactMap img {
    width: calc(100% - 7vw);
    object-fit: contain;
}

.knowMore {
    display: flex;
    width: 90%;
    margin-top: 3rem;
    justify-content: flex-end;
    color: #456396;
    font-size: 1.5rem;
    font-weight: 300;
}

.knowMore svg {
    position: relative;
    top: .25rem;
}

@media (max-width:1024px) {
    .floatInfo {
        font-size: 2rem;
        text-align: center;
        position: relative;
    }

    .logoHeader img {
        max-width: 10rem;
    }

    .floatTitle {
        margin: 0 0 1rem 0;
    }

    .floatText {
        align-items: center;
        padding: 0;
        gap: 1rem;
        margin-top: 4rem;
    }

    .infoTextImg {
        flex-direction: column-reverse;
        align-items: center;
    }

    .floatText img {
        width: 75%;
    }

    .contactDataContainer {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        margin-bottom: 5rem;
        gap: 3rem;
    }

    .contactInfoTitle,
    .contactData {
        width: 100%;
        margin-left: 0;
        text-align: center;
    }

    .contactMap {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .knowMore {
        margin: 0 0 3rem;
        justify-content: center;
        width: 100%;
    }
}