.Calendar-buttons {
    display: flex;
    justify-content: space-between;
}

.Calendar-buttons button {
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 30px;
    transition: 0.5s;
    background-color: #D9D9D9;
    color: #4A4A4A;
    font-size: 15px;
}

.Calendar-buttons button:hover {
    background-color: #B2B1B1;
}

.Calendar-buttons span {
    font-size: 20px;
    color: #000000;
    margin-top: 5px;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 10px;
}

.nav button,
.createButton {
    cursor: pointer;
    padding: 10px 40px;
    border: 2px solid #D9D9D9;
    border-radius: 10px;
    color: #4A4A4A;
    font-size: 15px;
    transition: background-color 0.8s;
}

.createButton {
    margin-top: .5rem;
}

.nav button:hover,
.createButton:hover {
    background-color: #B2B1B1;
    color: #fff;
}

.nav button.active {
    background-color: #34A8E9;
    color: #fff;
}

.printButton {
    display: flex;
    justify-content: center;
    width: 10rem;
    color: #AFafaf;
    border: 1px solid #afafaf;
    padding: 0.25rem;
    border-radius: 1rem;
    margin-top: 3rem;
}

.printButton:hover {
    background-color: #AFafaf;
    color: black;
}

.createForm {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-top: 1rem;
}

.downloadButton {
    cursor: pointer;
    padding: 10px 20px;
    border: 4px solid #D9D9D9;
    border-radius: 10px;
    color: #4A4A4A;
    font-size: 18px;
    gap: 18px;
    transition: background-color 0.8s;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.downloadButton:hover {
    background-color: #B2B1B1;
    color: #fff;
}

.centerModalDetail {
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: center; */
    justify-content: space-between;
}