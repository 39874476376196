.foundContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
}

.foundOption {
    width: 75%;
    border-radius: 1.25rem;
    background: #FFF;
    box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1.5rem;
    gap: 1rem;
    position: relative;
}

.foundOption .buttonCont {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 1.5rem;
    gap: 1rem;
}

.modalCancelQuestion {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 3rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.modalButtonsContainer {
    display: flex;
    margin-top: 3rem;
    gap: 2rem;
    align-items: center;
}

.buttonBack,
.buttonCancelConfirm {
    border-radius: 1.5rem;
    background: #ECECEC;
    min-width: 6.5rem;
    height: 2.6875rem;
    color: #000;
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
}

.buttonCancelConfirm {
    background: #B4514B;
    color: white;
    width: 19.8125rem;
    height: 3.625rem;
    font-size: 1.75rem;
    font-weight: 500;
}

.buttonCancelConfirm:hover {
    background: #833b33
}

@media (max-width:1024px) {
    .foundContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .modalCancelQuestion {
        font-size: 2rem;
    }

    .buttonCancelConfirm {
        width: 12rem;
    }
}