.rotateContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.rotateContainer span {
    width: 90%;
    text-align: center;
    font-size: 2rem;
    font-family: "Roboto";
    font-weight: 300;
}

.rotateIcon svg {
    display: inline-block;
    animation: rotateAnimation 2s linear infinite;
    fill: #acacac;
}

.rotateIcon svg {
    width: 125px;
    height: 125px;
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(0deg);
    }
}