.footer {
    background: #F0F0F0;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.footer img {
    max-height: 2rem;
}

.footerElements {
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 5rem;
}

.columnTitle {
    font-weight: 500;
}

.firstColumn img {
    width: min-content;
    object-fit: contain;
    max-height: 3rem;
}

.firstColumn,
.secondColumn,
.thirdColumn {
    display: flex;
    flex-direction: column;
    width: calc((100% / 3) - 10rem);
    gap: 2rem;
    margin-top: 3rem;
}

.iconLinesCont {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;
    align-items: flex-start;
}

.iconLine {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.socialCont {
    display: flex;
    gap: 1rem;
}

.devBy {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    font-size: .75rem;
}

.devBy img {
    top: 0.1rem;
    position: relative;
}

@media (max-width:1024px) {
    .footerElements {
        flex-direction: column;
        gap: .5rem;
    }

    .firstColumn,
    .secondColumn,
    .thirdColumn {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .devBy {
        justify-content: center;
        margin-top: 2rem;
    }
}