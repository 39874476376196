.about-item {
    display: flex;
    margin: 3rem 7rem;
}

.about-text,
.about-img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-text {
    color: #000;
    font-family: Roboto;
    font-size: 2rem;
    font-style: normal;
    font-weight: 300;
    line-height: 3.125rem;
    flex-direction: column;
}

.about-img img {
    width: 17rem;
    height: 17rem;
}

@media (max-width:1024px) {
    .about-item {
        flex-direction: column-reverse !important;
        margin: 2rem 1rem;
        align-items: center;
    }

    .about-text,
    .about-img {
        width: 100%;
        font-size: 1.25rem;
    }

    .about-img img {
        width: 11rem;
        height: 11rem;
    }
}