.center {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.logoAdmin {
    width: 15rem;
    padding: 3rem 0;
}

.logoAdminMobile {
    width: 25%;
    padding: 1rem;
}

.headerMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.menuContainer {
    display: flex;
    flex-direction: column;
    background-color: #506D9D8A;
    min-width: 20.75rem;
    height: 100vh;
    position: fixed;
}

.userContainer {
    background: #506D9D;
    min-height: 9rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    justify-content: center;
}

.userContainer img {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
    border-radius: 100%;
}

.userContainer span {
    color: #FFF;
    text-align: center;
    font-family: "Roboto";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 11rem;
    max-height: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.menuButton {
    border: none;
    background-color: transparent;
    display: flex;
    padding: 0.5rem 2.5rem;
    cursor: pointer;
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

a:has(.menuButtonSelected) {
    background-color: rgba(80, 109, 157, 1);
}

.menuButtonSelected {
    color: white;
}

.editorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 20.75rem);
    height: max-content;
    position: absolute;
    left: 20.75rem;
    padding-bottom: 3rem;
}

.editorConfig {
    border: 1px solid rgba(0, 0, 0, 0.30);
    background: #F7F7F7;
    width: 90%;
}

.editorRow,
.MuiAccordionSummary-root {
    display: flex;
    width: 100%;
    justify-content: space-between !important;
    min-height: 9.5rem !important;
    align-items: center;
}

.MuiAccordionSummary-gutters {
    background-color: #F7F7F7 !important;
    padding: 0 !important;
}

.editorHeader {
    min-height: 3rem !important;
}

.editorRowItem {
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiPaper-elevation {
    box-shadow: none !important;
}

.MuiAccordionDetails-root {
    padding: 0 !important;
}

.MuiAccordionSummary-contentGutters {
    position: absolute;
    left: 9% !important;
    color: #000;
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    left: calc(66.6% + 66.6%/4) !important;
}


.editorRow span {
    color: rgba(0, 0, 0, 0.90);
    text-align: center;
    font-family: "Roboto";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
}

img.editorRowItem {
    width: 8.625rem;
    height: 5.6875rem;
    padding: 1.5rem 0;
    object-fit: contain;
}

button.editItem {
    width: 2.25rem;
    height: 2.25rem;
    background-color: #BAC8E0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 100%;
    transition: transform 0.3s ease-in-out;
}

button.editItem:hover {
    transform: scale(1.25);
}

button.editItem svg {
    fill: rgba(0, 0, 0, 0.5);
}

.editorRowItem.buttonContainer {
    gap: 1.5rem;
}

.addItem,
.cancelItem {
    border-radius: 1.46875rem;
    background: #BAC8E0;
    width: 8.3125rem;
    height: 2.9375rem;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 1rem 0;
}

.addItem:hover {
    background: #89a5d5;
}

.editBoxImage {
    width: 15rem;
    height: 15rem;
    object-fit: contain;
}

.editBoxClose {
    position: absolute;
    top: .5rem;
    right: .5rem;
}

.modalDeleteActions {
    display: flex;
    gap: 1rem;
}

.cancelItem {
    background-color: transparent;
}

.cancelItem:hover {
    text-decoration: underline;
}

.drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    padding: 10px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    min-width: 90%;
    transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
    background: #eee;
    border-color: #111;
}

.drop-container:hover .drop-title {
    color: #222;
}

.drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
}

input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #BAC8E0;
    padding: 10px 20px;
    border-radius: 10px;
    color: #000;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: #89a5d5;
}

.logoutButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    gap: .5rem;
    border: 1px solid black;
    border-radius: 1.46875rem;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 1rem 0;
    transition: transform 0.3s ease;
}

.logoutButton:hover {
    transform: scale(1.05);
}

.overlayNoLogin {
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.25)),
        url(https://slidescorner.com/wp-content/uploads/2023/02/01-Medical-Background-HD-Light-Blue-Hexagons-by-SlidesCorner.com_-1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}


.containerNoLogin {
    background-color: white;
    width: 25%;
    border-radius: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    padding: 1rem 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.containerNoLogin span {
    text-align: center;
}

.dayHourPickerContainer {
    gap: 1rem;
    margin-bottom: 1rem;
}

.banRow,
.banRowInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 250px;
}

.banRowAdd {
    display: flex;
    justify-content: center;
    width: 10rem;
    color: #AFafaf;
    border: 1px solid #afafaf;
    padding: 0.25rem;
    border-radius: 1rem;
}

.banRowAdd:hover {
    background-color: #AFafaf;
    color: black;
}

.containerNoLogin .logoAdmin {
    display: block;
}

@media (max-width:1024px) {
    .editorContainer {
        width: 100%;
        position: initial;
    }

    .logoAdmin {
        display: none;
    }

    .containerNoLogin {
        width: 60%;
    }
}